import { parse } from "marked";
import DOMPurify from "isomorphic-dompurify";

export function renderSanitizedHTML(input) {
  if (!input) {
    return;
  }

  const rawHtml = parse(input);
  return DOMPurify.sanitize(rawHtml, { ADD_ATTR: ["target"] });
}
