<script>
  import { marked } from "marked";
  import { renderSanitizedHTML } from "$lib/utilities";
  import { isExternalUrl, correctedUrl } from "$lib/utilities/url-helpers";
  import { textToId } from "$lib/utilities/dom-helpers";

  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";

  export let input;
  export let id;
  export let element = "div";
  let clazz = "";
  export { clazz as class };
  let isExternal = false;

  let externalLinks = {};
  let origin;

  $: origin = $page?.url?.origin;

  marked.use({
    extensions: [
      {
        name: "strong",
        renderer(token) {
          return `<span class="font-bold">${token.text}</span>`;
        }
      },
      {
        name: "link",
        renderer(token) {
          let href = token.href;
          let text = token.text;
          isExternal = isExternalUrl(href, origin);
          let linkToRender = correctedUrl(href);

          if (isExternal) {
            const externalId = `markdown-link-${id}` || `markdown-link-${textToId(text)}`;
            externalLinks[externalId] = text;

            return `<a
              id="${externalId}"
              class="markdown-external w-fit outline-2 outline-offset-4 focus-visible:outline-dashed cursor-pointer relative underline inline text-black outline-black focus-visible:border-black hover:before:w-[0px] hover:text-cobalt-blue"
              data-sveltekit-reload
              href="${linkToRender}"
              aria-haspopup="false"
              aria-expanded="false"
              target="_blank"
              rel="noopener noreferrer"
              ></a>`;
          } else {
            return `<a
              id="${id}"
              class="markdown-external w-fit outline-2 outline-offset-4 focus-visible:outline-dashed cursor-pointer relative underline inline text-black outline-black focus-visible:border-black hover:before:w-[0px] hover:text-cobalt-blue"
              data-sveltekit-reload
              href="${linkToRender}"
            >${text}</a>`;
          }
        }
      }
    ]
  });

  marked.Renderer.prototype.paragraph = (text) => {
    return `<p>${text}</p>`;
  };

  let htmlText = renderSanitizedHTML(input);
</script>

{#if input}
  <svelte:element this={element} class={clazz} {id}>
    <!-- eslint-disable-next-line svelte/no-at-html-tags -->
    {@html htmlText}
  </svelte:element>
{/if}
