<script>
  import { getContext, setContext } from "svelte";
  import { ImageContent, GridContainer } from "@ObamaFoundation/of-design-system";

  import { srcSetFromWidths, sizesForBreakpoints, SRCSET_WIDTHS } from "$lib/utilities";
  import { BREAK_POINTS } from "$lib/utilities/constants";

  import {
    buildSrc,
    altTextToDisplay,
    getImageAspectRatio,
    getClosestAspectRatioClass
  } from "$lib/utilities/components/image-helpers";
  import Markdown from "$lib/components/Markdown.svelte";

  // Required
  export let altText;
  export let imageAsset;
  export let mobileImageAsset;
  export let id;

  // With Defaults
  /** @type { string } */
  let className = "";
  export { className as class };

  export let caption;
  export let isFullBleed = false;
  export let aspectRatio;
  export let theme;

  const isContainerized = getContext("containerized");

  let resizeParams = {};

  // Boolean used to ignore the Contentful Image API image resizing logic
  // Introduced to remove chromatic image flakiness caused by small differences in images
  let ignoreContentfulImgResize = getContext("ignoreContentfulImgResize");

  // Image sizes (widths) for available breakpoints.
  // These can be set using any unit, but we recommend using vw (50vw, 100vw, etc.)
  // If you don't set these, the image will be full width at all breakpoints.
  let widthsAt = isContainerized ? { sm: "100vw", md: "75vw", lg: "50vw" } : { sm: "100vw" };

  let screenWidth = 0;

  $: currentlyDisplayedImage =
    screenWidth < BREAK_POINTS.md && mobileImageAsset ? mobileImageAsset : imageAsset;

  $: imageAspectRatio = getImageAspectRatio(currentlyDisplayedImage, aspectRatio);
  let alt = altTextToDisplay(altText);

  $: src = buildSrc(currentlyDisplayedImage, resizeParams, ignoreContentfulImgResize);
  $: srcset = !ignoreContentfulImgResize
    ? srcSetFromWidths(currentlyDisplayedImage?.fields?.file?.url, SRCSET_WIDTHS, resizeParams)
    : undefined;
  const sizes = sizesForBreakpoints(widthsAt);
  const closestAspectRatioClass = getClosestAspectRatioClass(
    currentlyDisplayedImage?.fields?.file?.details?.image?.width,
    currentlyDisplayedImage?.fields?.file?.details?.image?.height
  );

  setContext("containerized", true);
</script>

<svelte:window bind:innerWidth={screenWidth} />

<GridContainer
  {id}
  variant={isFullBleed || isContainerized ? "bleed" : "regular"}
  containerClass={className}
>
  {#if caption}
    <ImageContent
      aspectRatio={imageAspectRatio ? imageAspectRatio : closestAspectRatioClass}
      isCaptionFullBleed={isFullBleed && !isContainerized}
      image={{ src, alt, srcset, sizes, theme }}
    >
      <Markdown element="span" input={caption} id="markdown-caption-${id}" slot="caption" />
    </ImageContent>
  {:else}
    <ImageContent
      aspectRatio={imageAspectRatio ? imageAspectRatio : closestAspectRatioClass}
      isCaptionFullBleed={isFullBleed && !isContainerized}
      image={{ src, alt, srcset, sizes, theme }}
    />
  {/if}
</GridContainer>
